<template>
  <v-menu offset-y :close-on-click="true">
    <template #activator="{ on }">
      <v-btn
        color="secondary"
        text
        outlined
        :disabled="!selectedUser"
        v-on="on"
      >
        <v-icon left>$dots-vertical</v-icon>
        {{ $trans("additional_options") }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(el, i) in userActions"
        :key="i"
        @click="el.action(dataToSend, $route.name)"
      >
        <v-list-item-title :class="el.class ? el.class : ''">
          {{ $trans(el.title) }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import get from "lodash.get";
import { mapActions, mapGetters } from "vuex";
import userActions from "@/calendesk/mixins/userActions";
import confirmDialogTypes from "@/components/ConfirmDialogs/confirmDialogTypes";
import {
  hasOnlyRole,
  userHasRole,
} from "@/lib/calendesk-js-library/tools/helpers";

export default {
  name: "EmployeeAppBarMenu",
  mixins: [userActions],
  computed: {
    ...mapGetters({
      selectedUser: "user/getSelectedUser",
      selectedEmployee: "employee/getSelectedEmployee",
      refreshUsersStatus: "user/shouldRefreshUsers",
    }),
    dataToSend() {
      if (this.selectedUser) {
        return [
          {
            id: this.selectedUser.id,
            user: this.selectedUser,
            employeeId: get(this.selectedEmployee, "id"),
            status: this.selectedUser.status,
          },
        ];
      }

      return [];
    },
    userActions() {
      const actions = [
        {
          title: "send_employee_notification_title",
          action: this.sendCustomerNotification,
        },
      ];

      actions.push({
        title: "reset_password",
        action: this.handleResetPasswordClick,
      });

      if (
        !this.$helpers.userHasRole(
          this.selectedUser,
          this.$helpers.roleSlugs.root
        )
      ) {
        actions.push({
          title: this.userIsActive ? "block_account" : "unblock_account",
          action: this.userIsActive ? this.handleLock : this.handleUnlock,
          class: "accent_light--text",
        });

        actions.push({
          title: "delete_user",
          action: this.handleRemove,
          class: "accent_light--text",
        });
      }

      return actions;
    },
    userIsActive() {
      return this.selectedUser && this.selectedUser.status === "active";
    },
  },
  watch: {
    refreshUsersStatus(val) {
      val && this.getUser(this.selectedUser.id);
    },
  },
  methods: {
    ...mapActions({
      getUser: "user/get",
    }),
    sendCustomerNotification() {
      this.sendNotificationsToUsers(
        [this.$helpers.cloneObject(this.selectedEmployee)],
        this.$helpers.customerNotificationCustomerType.employee,
        this.$trans("send_employee_notification_title")
      );
    },
    handleResetPasswordClick(data) {
      this.handleResetPassword(data[0].user, true);
    },
  },
};
</script>
